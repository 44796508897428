import React from 'react'

import FunnyImage from '../assets/img/monstore/TELKOMSELASSET-02.png'

import Layout from "../components/layout"
import SEO from "../components/seo"

import SubmitForm from "../templates/redeem-form"
import SuccessPage from "../templates/success"
import FailedPage from "../components/pages/no-valid"

import { Button } from "../components/anti/buttons/buttons"
import { Container, Section } from "../components/anti/grid/grid"

const TselPage = () => {

  return (
    <>
      {/* <Layout>
        <SEO title="Home" />
        <Section>
          <Container className="mw-sm">
            <h1>Redeem</h1>
            <form>
              Form here
               <Button variant="primary" className="mt-4">
                Redeem
               </Button>
            </form>
          </Container>
        </Section>
      </Layout> */}
      <div className="success-page py-main">
        <div className="d-flex mt-6 justify-content-center align-items-center ">
          <img src={FunnyImage} className="image-home" alt="funny png" />
        </div>
        <div className="title-home w-100 d-flex justify-content-center align-items-center">
          <h3 className="text-center text-light">
            PENUKARAN TELKOMSEL POIN
          </h3>
        </div>
      </div>
    </>
  )
}


export default TselPage
